import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["copiedMessageContainer"];
  static values = { text: String }

  copy() {
    const textArea = this.createTextArea();
    this.copyToClipboard(textArea);
    this.removeTextArea(textArea);
    this.copied();
  }

  createTextArea() {
    const textArea = document.createElement("textarea");
    textArea.value = this.textValue;
    document.body.appendChild(textArea);
    return textArea;
  }

  copyToClipboard(textArea) {
    textArea.select();
    document.execCommand("copy");
  }

  removeTextArea(textArea) {
    document.body.removeChild(textArea);
  }

  copied() {
    const successMessage = document.createElement("span");
    successMessage.textContent = I18n.t("titles.link_copied");
    this.copiedMessageContainerTarget.appendChild(successMessage);
  }
}
