import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["background", "sendCodeModal", "continueAsModal"];

  connect() {
    this.addDragEventListeners(this.sendCodeModalTarget);
    this.addDragEventListeners(this.continueAsModalTarget);
  }

  addDragEventListeners(modalTarget) {
    modalTarget.addEventListener("mousedown", this.startDrag.bind(this));
    modalTarget.addEventListener("mousemove", this.onDrag.bind(this));
    modalTarget.addEventListener("mouseup", this.endDrag.bind(this));
    modalTarget.addEventListener("touchstart", this.startDrag.bind(this));
    modalTarget.addEventListener("touchmove", this.onDrag.bind(this));
    modalTarget.addEventListener("touchend", this.endDrag.bind(this));
  }

  closeModal() {
    this.closeModalWithTimeout(this.sendCodeModalTarget);
    this.closeModalWithTimeout(this.continueAsModalTarget);
  }

  switchModals() {
    this.closeModal();
    setTimeout(() => { this.showSendCodeModal() }, 500);
  }

  backToSendCodeModal() {
    this.closeModal();
    setTimeout(() => { this.showSendCodeModal(); this.toggleForms(); }, 500);
  }

  toggleForms() {
    setTimeout(() => {
      document.getElementById("validate-code-form").classList.add("hidden");
      document.getElementById("send-code-form").classList.remove("hidden");
    }, 100);
  }

  showSendCodeModal() {
    this.showModal(this.sendCodeModalTarget);
  }

  showContinueAsModal() {
    this.showModal(this.continueAsModalTarget);
  }

  showModal(modalTarget) {
    this.backgroundTarget.classList.remove("hidden", "animate-fade-out");
    this.backgroundTarget.classList.add("animate-fade-in");
    modalTarget.classList.remove("hidden", "animate-to-bottom");
    modalTarget.classList.add("animate-from-bottom");
  }

  closeModalWithTimeout(modalTarget) {
    this.backgroundTarget.classList.remove("animate-fade-in");
    this.backgroundTarget.classList.add("animate-fade-out");
    modalTarget.classList.remove("animate-from-bottom");
    modalTarget.classList.add("animate-to-bottom");
    setTimeout(() => { this.backgroundTarget.classList.add("hidden"); }, 500);
  }

  startDrag(event) {
    this.dragging = true;
    this.startY = event.type === 'touchstart' ? event.touches[0].clientY : event.clientY;
  }

  onDrag(event) {
    if (!this.dragging) return;
    event.preventDefault();
    const currentY = event.type === 'touchmove' ? event.touches[0].clientY : event.clientY;
    const diffY = currentY - this.startY;
    this.checkDragThreshold(diffY);
  }

  checkDragThreshold(diffY) {
    if (diffY > 100) {
      this.closeModal();
      this.dragging = false;
    }
  }

  endDrag() {
    this.dragging = false;
  }
}
