import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { url: String };

  static targets = [
    "zipCode",
    "street",
    "complement",
    "neighborhood",
    "city",
    "state"
  ];

  connect() {
    this.searchWhenZipCodeIsFilled();
  }

  searchWhenZipCodeIsFilled() {
    this.zipCodeTarget.addEventListener("input", () => {
      if (this.zipCodeTarget.value.length === 9) { this.search(); }
    });
  }

  async search() {
    const response = await fetch(this.searchCepUrl());
    this.handleSearchResponse(response);
  }

  async handleSearchResponse(response) {
    if (response.ok) {
      this.updateFields(await response.json());
    } else {
      const postalCodefield = document.getElementById("listener_postal_code");
      const errorSpan = document.createElement("span");
      errorSpan.className = "help-block has-error";
      errorSpan.textContent = I18n.t("errors.messages.zip_code_not_found");
      postalCodefield.parentNode.insertBefore(errorSpan, postalCodefield.nextSibling);
    }
  }

  searchCepUrl() {
    const url = new URL(this.urlValue);
    url.searchParams.append("zip_code", this.zipCodeTarget.value);
    return url;
  }

  updateFields(cepData) {
    this.neighborhoodTarget.value = cepData.neighborhood;
    this.cityTarget.value = cepData.city;
    this.stateTarget.value = cepData.state;
    if (this.hasStreetTarget) { this.streetTarget.value = cepData.street; }
    if (this.hasComplementTarget) { this.complementTarget.value = cepData.complement; }
  }
}
